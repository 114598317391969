import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { TellerService } from 'src/app/services/teller.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ApiService } from 'src/app/services/api.service';
import { ExportExcelService } from 'src/app/services/export-excel.service';

@Component({
  selector: 'app-finance-daybook',
  templateUrl: './finance-daybook.component.html',
  styleUrls: ['./finance-daybook.component.css']
})
export class FinanceDaybookComponent implements OnInit {
  book: any[]; agents: any[]=[]; groups: any[]=[]; officer: any[]=[]; trans: any[]; 
  types: any[]=["Officer","Agent", ]; rp: any; rpt: any; cash: any; amount: number = 0; res: any;
  constructor(private api: ApiService, private ext: ExportExcelService, public use: NavComponent) { }

  ngOnInit() {
    this.init();
    this.getAll();
  }

  getAll() {
    // this.api.get('agent?limit=100').subscribe((data: any) => {
    //   this.agents = data.data;
    // }, (err: HttpErrorResponse) => { });
    this.api.get('officer?limit=300').subscribe((data: any) => {
      this.officer = data.data;
    }, (err: HttpErrorResponse) => { });
  }
  Search(value) {
    if(value) {
      this.api.get('officer?limit=100&search='+value).subscribe((data: any) => {
        this.officer = data.data;
      }, (err: HttpErrorResponse) => { });
    }
  }

  getDayBook() {
    this.api.post('officer/statement',this.rpt).subscribe((data: any) => {
      this.book = data.trans; this.amount = data.amount;
      console.log(data);
    }, (err: HttpErrorResponse) => { });
  }
  export() {
    if (this.book.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'payment');
      this.use.showMsg('success', 'Transactions Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Transactions Export Fail', 'There is no Transactions Data to Export');
    }
  }

  format() {
    var id = 1; var exp = [];
    this.book.forEach((e: any) => {
      const ed = {
        SN: id, CODE: e.code, TYPE: e.type, MOBILE: e.mobile, SOURCE: e.source,
        AMOUNT: e.amount, TRAN_REF: e.tranid,  REFERENCE: e.reference, PAYMENT_STATUS: e.status_message, 
        PENSION_STATUS: e.bank_status_message, DATE: e.date
      };
      exp.push(ed); id++;
    });
    return exp;
  }

  init(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.rp = { type: '', id: '', date: null, start: null, end: null };
      this.rpt = { type: '', id: null, date: null, start: null, end: null };
      this.cash = { opening: 0, credit: 0, debit: 0, withdrawal: 0, deposit: 0, balance: 0 };
    }
  }

}
