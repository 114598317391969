import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-setup-branch',
  templateUrl: './setup-branch.component.html',
  styleUrls: ['./setup-branch.component.css']
})
export class SetupBranchComponent implements OnInit {
  ch:any; add: Boolean; edit: Boolean; search: string; channels: any[] = []; response: any; merchants:any[]=[];
  constructor(private api: ApiService, private use: NavComponent) { }

  ngOnInit() {
    this.getMerchant();
    this.init();
    this.getApikey();
  }

  listView() {
    this.add = true; this.edit = false;
  }
  back() {
    this.add = false;
  }
  
  editChannel(data) {
    // this.set = this.charges.find(s => s.chargeid === data);
    this.ch = data;
    this.edit = true;
  }
  
  getApikey(page=1,limit=100) {
    this.api.get('apikey?page='+page+'&limit='+limit).subscribe((data: any) => {
      this.channels = data; this.response = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }
  
  getMore(page,limit) {
    this.api.get('apikey?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.channels = data.data; this.response = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  getMerchant() {
    this.api.get('merchant?limit=100').subscribe((data: any) => {
      this.merchants = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  SaveChanel() {
    if(this.ch.apikeyid > 0) {
      this.putChannel();
    } else {
      this.postChannel();
    }
  }
  
  postChannel() {
    this.api.post('apikey',this.ch).subscribe((data: any) => {
      this.init(); this.getApikey();
      this.use.showMsg('success', 'Channel Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Channel');
    });
  }

  putChannel() {
    this.api.put('apikey/', this.ch).subscribe((data: any) => {
      this.init(); this.getApikey();
      this.use.showMsg('success', 'Channel Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  init() {
    this.ch = { apikeyid: 0, name: '', appid: '', key: '', reference: '', active: true, merchantid: this.use.active.merchantid, userid: this.use.active.id, }
  }

}
