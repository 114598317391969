import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ManagerService } from 'src/app/services/manager.service';
import { SetupService } from 'src/app/services/setup.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-apply-cot',
  templateUrl: './apply-cot.component.html',
  styleUrls: ['./apply-cot.component.css']
})
export class ApplyCotComponent implements OnInit {
  @ViewChild('applyForm') public any: NgForm;
  accounts: any[] = []; charges: any[] = []; new: any; set: any; res: any;
  constructor(private api: ApiService, private _use: NavComponent, private _api: ManagerService, private _set: SetupService) { }

  ngOnInit() {
    this.formReset();
    this.getAllAccounts();
  }

  getAllAccounts() {
    this._api.getAccounts().subscribe((data: any) => {
      this.accounts = data.data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  getAcct(id) {
    this._api.getAccountbyNumber(id).subscribe((data: any) => {
      this.getCharge(data.accounttype.cotid);
      this.charges = data.charges;
      this.new.status = data.status;
      this.new.balance = data.balance;
      this.set.accountid = data.accountid;
      this.new.fullname = data.customer.fullname;
      this.new.accounttype = data.accounttype.name;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Get Account Details');
    });
  }

  getCharge(id) {
    this._set.getCot(id).subscribe((data: any) => {
      this.set.amount = data.amount; console.log(data.amount);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Get Cot Amount');
    });
  }

  postStatus() {
    this._api.postAccountCharge(this.set).subscribe((data: any) => {
      this.res = data; this.formReset(this.any);
      this._use.showMsg('success', 'Charge Applied', 'Account Charge Applied Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Charge Fail', 'Unable to Apply Account Charge');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.set = {
        accountid: '', amount: '', reference: 'Cot Charge',
        userid: this._use.active.Id, date: this._use.date
      };
      this.init();
    }
  }

  init() {
    this.new = {
      fullname: '', accounttype: '', status: '', number: '', balance: '',
      userid: this._use.active.Id, date: new Date(),
    };
  }

}
