import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { TellerService } from 'src/app/services/teller.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-teller-transfer',
  templateUrl: './teller-transfer.component.html',
  styleUrls: ['./teller-transfer.component.css']
})
export class TellerTransferComponent implements OnInit {
  @ViewChild('transferForm') public any: NgForm;
  teller: any[]; grant: boolean; tf: any; cash: any; res: any;
  constructor(private _api: TellerService, private _use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAllTellers();
  }

  getAllTellers() {
    this._api.getTellers().subscribe((data: any) => {
      this.teller = data; this.from(this._use.active.Id);
    }, (err: HttpErrorResponse) => { /*location.reload(true);*/ });
  }

  from(val) {
    console.log(val);
    this.teller.forEach((e: any) => {
      if (e.id === val) {
        this.tf.id = JSON.stringify(e.tellerid);
        this._api.getTellerBalance(e.tellerid).subscribe((data: any) => {
          this.cash.from = data;
        }, (err: HttpErrorResponse) => {
          this._use.showMsg('error', 'Teller Balance', 'Unable to Your current Balance');
        });
      }
    });
  }
  to(val) {
    console.log(val);
    this.teller.forEach((e: any) => {
      if (e.tellerid === val) {
        this.tf.nominalid = e.nominalid;
        this._api.getTellerBalance(e.tellerid).subscribe((data: any) => {
          this.cash.to = data;
        }, (err: HttpErrorResponse) => {
          this._use.showMsg('error', 'Teller Balance', 'Unable to Teller current Balance');
        });
      }
    });
  }

  postTransfer() {
    this._api.postTransfer(this.tf).subscribe((data: any) => {
      this.res = data; this.formReset();
      this._use.showMsg('success', 'Transfer Successfull', 'Transfer Transaction has been posted successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Transfer Fail', 'Unable to Process Transfer'); });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.tf = { id: 0,
        transcode: '', type: '', source: '', method: 'cash', amount: 0, nominalid: 0,
        reference: '', tellerid: '', userid: this._use.active.Id, date: this._use.date
      };
      this.cash = { frombal: 0, from: 0, tobal: 0, to: 0};
    }
  }

}
