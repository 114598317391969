import { Component, OnInit, ViewChild } from '@angular/core';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';
import { SetupService } from 'src/app/services/setup.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {

  content: any; add: Boolean; edit: Boolean = false; curr: Date; response: any; search: string = '';
  contents: any[] = [];  filtered: any[]; 
  types:any[] = ["DEPOSIT", "WITHDRAWAL"]; user: any;
  constructor(private api: ApiService, private use: NavComponent) { }

  ngOnInit() {
    this.user = this.use.active;
    this.curr = new Date();
    this.formReset();
  }

 
  saveContent() {
    if(this.content.chargeid > 0) {
      this.putContent();
    } else {
      this.postContent();
    }
  }

  postContent() {
    this.api.post('content',this.content).subscribe((data: any) => {
      this.formReset();
      this.use.showMsg('success', 'Content Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New FAQ');
    });
  }

  putContent() {
    this.content.muserid = this.use.active.id; this.content.mdate = new Date();
    this.api.put('content/', this.content).subscribe((data: any) => {
      this.formReset();
      this.use.showMsg('success', 'Content Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.content = {
        chargeid: 0, content: "",   userid: this.use.active.id, date: new Date(), muserid: null, mdate: null
      };
      this.edit = false;
    }
  }

  back() {
    this.formReset();
  }

}







