import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/services/manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { SigninService } from 'src/app/services/signin.service';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  user: any; dash: any; cp: any; bal: any;
  constructor(private api: ApiService, private ser: SigninService, private _api: ManagerService, public toastr: ToastrService) { }

  ngOnInit() {
    this.init();
    this.user = this.ser.userDetails(); console.log(this.user);
    this.formReset();
    this.Dashboard();
  }

  Dashboard() {
    // this.showMsg('success', 'Welcome', 'You Are Welcome @' + this.user.FullName);
    this._api.getDashboard().subscribe((data: any) => {
      this.dash = data;
    }, (err: HttpErrorResponse) => {
      this.showMsg('error', 'Error', 'Error fetching dashboard data');
    });
    // this.api.get('merchant/'+this.user.merchantid).subscribe((data: any) => {
    //   this.api.getBalance('Paynow/merchant/'+data.apikey+'/'+data.code).subscribe((data: any) => {
    //     this.bal = data;
    //   }, (err: HttpErrorResponse) => {
    //     this.showMsg('error', 'Error', 'Error fetching Float Balance');
    //   });
    // }, (err: HttpErrorResponse) => {
    //   this.showMsg('error', 'Error', 'Error fetching dashboard data');
    // });
  }

  postPassword() {
    this.ser.changePassword(this.cp).subscribe((data: any) => {
      this.cp = data;
    }, (err: HttpErrorResponse) => {
      this.showMsg('error', 'Changing Password', 'Current Password is not correct');
    });
  }

  showMsg(type, title, message) {
    if (type === 'success') {
      this.toastr.success(message, title);
    } else if (type === 'warning') {
      this.toastr.warning(message, title);
    } else if (type === 'error') {
      this.toastr.error(message, title);
    }
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.dash = { customers: 0, female: 0, male: 0, account: 0, savings: 0, loans: 0,
        income: 0, cashbook: 0, assert: 0, teller: 0, expense: 0, liability: 0 };
      this.cp = {
        username: this.user.unique_name, oldpassword: '', newpassword: '', image: '',
        muserid: this.user.Id, mdate: new Date()
      };
    }
  }
  init() {
    this.user = { FullName: '', Mobile: '', Image: '', UserType: '', Email: '', Company: '' };
  }

}
