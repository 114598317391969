import { Injectable } from '@angular/core';
import { Router, Routes, RouterStateSnapshot } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpUserEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    state: RouterStateSnapshot; url: any; cur: any;
    constructor(private router: Router, public toastr: ToastrService, private jwtHelper: JwtHelperService) {}
    intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.cur = req.url;
        this.url = this.cur.split('/').slice(-2)[0]; // console.log(this.url);
        if (this.url === 'upload') {
            req = req.clone({
                setHeaders: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ` + this.jwtHelper.tokenGetter()
                }
            });
        } else {
            req = req.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ` + this.jwtHelper.tokenGetter()
                }
            });
        }
        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // do stuff with response if you want
                // console.log(event);
                if (event.body && event.ok) {
                    if(event.body.message) { 
                        // this.notify.success(event.body); 
                    }
                }
            }
        }, (err: any) => {
            // this.error.log(err); // console.log(err);
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    // this.notify.notify('Login Session Expired Please Login Again');
                    // auto logout if 401 response returned from api
                    this.toastr.error('Login Session Expired Please Login Again', 'UnAuthorize');
                    localStorage.removeItem('Token');
                    location.reload(true);
                    // return this.router.navigate(['/login'], { queryParams: { returnUrl: this.state.url } });
                    return false;
                }else {
                    const msg = err.error.message || err.message;
                    this.toastr.error(msg, 'Error');
                }
            } else {
                // console.log(err);
                // console.log(err.error);
                // console.log(err.error.message);
                const msg = err.error.message || err.message;
                this.toastr.error(msg, 'Error');
            }
            
            // this.notify.error(msg);
        }));
        // return next.handle(req).pipe(catchError(err => {
        //     if (err.status === 401) {
        //         // auto logout if 401 response returned from api
        //         this.toastr.error('Login Session Expired Please Login Again', 'UnAuthorize');
        //         // alert('UnAuthorize Please Login Again');
        //         localStorage.removeItem('Token');
        //         location.reload(true);
        //         this.router.navigate(['/login'], { queryParams: { returnUrl: this.state.url } });
        //     }

        //     const error = err.error.message || err.statusText;
        //     return throwError(error);
        // }));
    }
}

