import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SigninService } from 'src/app/services/signin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  log: any; isLoginError: boolean; res: any;
  constructor(private _api: SigninService, private router: Router) { }

  ngOnInit() {
    this.init();
  }

  Reset() {
    this._api.resetUser(this.log).subscribe((data: any) => {
      this.router.navigate(['/login']); location.reload(true);
    }, (err: any) => { this.isLoginError = true; console.log(err); });
  }

  init(form?: NgForm) {
    if (form != null) { form.reset(); }
    this.log = { email: '' };
  }

}
