import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.css']
})
export class AgentsComponent implements OnInit {
  agents: any; filteredAgent: any[] = []; response: any; search: string = '';
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = value;
    this.getMore(1,10);
    // this.filteredAgent = this.serachResult(value);
  }
  constructor(private api: ApiService, private use: NavComponent, private _exp: ExportExcelService) { }

  ngOnInit() {
    this.getAllAgents();
  }

  getAllAgents() {
    this.api.get('agent').subscribe((data: any) => {
      this.agents = data; this.response = data; console.log(data);
      this.filteredAgent = this.agents.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Agent List Fail', 'Unable to Fetch Agent List');
    });
  }

  getMore(page,limit) {
    this.api.get('agent?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.agents = data; this.response = data; console.log(data);
      this.filteredAgent = this.agents.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Agent List Fail', 'Unable to Fetch Agent List');
    });
  }
  
  reset(val) {
    val.pin = '1234';
    this.api.put('agent/'+val.agentid, val).subscribe((data: any) => {
      this.use.showMsg('success', 'Pin Reset', 'Agent Pin Reset Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Pin Reset Fail', 'Unable to Reset Agent Pin');
    });
  }
  
  Export() {
    this.Sort().then((data)=>{
      // return data;
      this._exp.exportAsExcelFile(data, 'group');
    });
    // this._exp.exportAsExcelFile(this.filteredAgent, 'group');
  }

  async Sort() {
    const agents = [];
    await this.filteredAgent.forEach((c)=>{
      const grp = { 
        code: c.code, name: c.name, leader: c.group_leader, mobile: c.mobile, secretary: c.group_secretary, secretary_mobile: c.group_secretary_mobile, status: c.active == true? 'Active': 'Disabled', creationTime: c.date 
      };
        agents.push(grp);
    });
    return agents;
  }

  serachResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.agents.filter(a => a.number.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.customer.fullname.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.status.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.grouptype.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.grouptype.basetype.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }

}
