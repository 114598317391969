import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TellerService } from 'src/app/services/teller.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-finance-cashbook',
  templateUrl: './finance-cashbook.component.html',
  styleUrls: ['./finance-cashbook.component.css']
})
export class FinanceCashbookComponent implements OnInit {
  book: any[]; cash: any; res: any;
  constructor(private api: ApiService, private _api: TellerService, private _use: NavComponent) { }

  ngOnInit() {
    this.getAll();
  }

  getAll() {
    this._api.getCashbook().subscribe((data: any) => {
      this.book = data;
    }, (err: HttpErrorResponse) => { });
  }

}
