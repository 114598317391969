import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-add-relation-officer',
  templateUrl: './add-relation-officer.component.html',
  styleUrls: ['./add-relation-officer.component.css']
})
export class AddRelationOfficerComponent implements OnInit {
  @ViewChild('officerForm') public any: NgForm;
  off: any; edit: Boolean; res: any; id: any; accounts: any[] = [];
  loc: any[] = []; cust: any[] = []; users: any[] = [];
  constructor(private api: ApiService, private _nav: Router, private _route: ActivatedRoute, private use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAll();
  }

  getAll() {
    this.id = this._route.snapshot.paramMap.get('id');
    this.getGrp(this.id);
    this.api.get('location/all').subscribe((data: any) => {
      this.loc = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Location List Fail', 'Unable to Fetch Location List');
    });
  }

  getGrp(id: any) {
    if (id && id !== 0) {
      this.edit = true;
      this.api.get('officer/'+id).subscribe((data: any) => {
        this.off = data; this.accounts = data.account; // this.Selected();
      }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', 'Fetching Fail', 'Unable to Get Field Officer Details'); });
    } else {
      this.edit = false;
      this.formReset();
    }
  }

  saveOfficer() {
    if(this.off.officerid > 0) {
      this.putOfficer();
    } else {
      this.postOfficer();
    }
  }

  postOfficer() {
    this.api.post('officer',this.off).subscribe((data: any) => {
      this.res = data; this.formReset(this.any);
      this.use.showMsg('success', 'Field Officer Created', 'Added to list Successfully');
      this._nav.navigate(['/officers']);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Creating Fail', 'Unable to Add New Field Officer');
    });
  }

  putOfficer() {
    this.off.muserid = this.use.active.id; this.off.mdate = new Date();
    this.api.put('officer/'+this.off.officerid, this.off).subscribe((data: any) => {
      this.res = data; this.formReset(this.any);
      this.use.showMsg('success', 'Field Officer Updated', 'Field Officer Details Updated Successfully');
      this._nav.navigate(['/officers']);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updating Fail', 'Unable to Update Field Officer Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.off = {
        officerid: 0, code: null, name: '', email: '', mobile: '', pin: '1234', percentage: 0, locationid: '',
        startdate: '', enddate: '', active: true, status: 'Active', merchantid: this.use.active.merchantid,
        userid: this.use.active.id, date: this.use.date, muserid: null, mdate: null
      };
    }
  }

}
