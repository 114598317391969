import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ManagerService } from 'src/app/services/manager.service';
import { ApiService } from 'src/app/services/api.service';
import { SetupService } from 'src/app/services/setup.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-referer',
  templateUrl: './referer.component.html',
  styleUrls: ['./referer.component.css']
})
export class RefererComponent implements OnInit {

  referer: any; add: Boolean; edit: Boolean = false; curr: Date; response: any; search: string = '';
  referers: any[] = [];  filtered: any[]; user: any;

  constructor(
    private use: NavComponent, 
    private _api: ManagerService,
    private api: ApiService, 
  ) { }


  ngOnInit() {
    this.user = this.use.active;
    this.curr = new Date();
    this.formReset();
  }

  getAllReferer() {
    this.api.get('referer').subscribe((data: any) => {
      this.referers = data; this.filtered = data; this.response = data;
      this.use.showMsg('success', 'Referer List', 'Fetched Referer list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Referer List Fail', 'Unable to Fetch Referer List');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.referer = {
        refererid: 0, referername: "", source:"", refereename: "", mobile: "", active: true,  userid: this.use.active.id, date: new Date(), muserid: null, mdate: null
      };
      this.edit = false;
    }
  }
  

}


















