import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ManagerService } from 'src/app/services/manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {
  custs: any[] = []; add: boolean; filteredCust: any[] = []; response: any; search: string = '';
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = value;
    // this.getMore(1,8);
    // this.filteredCust = this.searchResult(value);
  }
  constructor(private api: ApiService, private use: NavComponent, private _api: ManagerService, private _exp: ExportExcelService) { }

  ngOnInit() {
    this.getAllCustomers();
  }

  getAllCustomers() {
    this._api.getCustomers().subscribe((data: any) => {
      this.custs = data.data; this.filteredCust = data.data; this.response = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Customer List Fail', 'Unable to Fetch Customer List'); });
  }
  
  getMore(page,limit) {
    this.api.get('customer?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.custs = data; this.response = data; console.log(data);
      this.filteredCust = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Customer List Fail', 'Unable to Fetch Customer List');
    });
  }

  reset(val) {
    val.pin = '';
    this.api.put('customer/'+val.customerid, val).subscribe((data: any) => {
      this.use.showMsg('success', 'Pin Reset', 'Customer Pin Reset Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Customer List Fail', 'Unable to Fetch Customer List');
    });
  }
  
  Delete(val) {
    // console.info(this.searchTitle);
    this.api.delete('customer/'+val.customerid).subscribe((data: any) => {
      this.getMore(1,8);
    });
  }

  Export() {
    this.Sort().then((data)=>{
      // return data;
      this._exp.exportAsExcelFile(data, 'customer');
    });
  }

  async Sort() {
    const cust = [];
    await this.filteredCust.forEach((c)=>{
      const cus = { code: c.code, name: c.fullname, type: c.type, mobile: c.mobile, gender: c.gender, dateofbirth: c.dateofbirth,
        locations: c.location.code, maritalstatus: c.maritalstatus, creationTime: c.date };
        cust.push(cus);
    });
    return cust;
  }

  searchResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.custs.filter(a => a.fullname.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.type.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.mobile.indexOf(searchString) !== -1);
  }

}
