import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/services/manager.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-approvals',
  templateUrl: './approvals.component.html',
  styleUrls: ['./approvals.component.css']
})
export class ApprovalsComponent implements OnInit {
  approvals: any[] = []; response:any;
  constructor(private use: NavComponent, private api: ManagerService) { }

  ngOnInit() {
    // this.getAllAccounts();
  }

  getAllAccounts() {
    this.api.getAccountsByStatus('Pending').subscribe((data: any) => {
      this.approvals = data.data; this.response = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

}
