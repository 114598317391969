import { Component, OnInit, ViewChild } from '@angular/core';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';
import { SetupService } from 'src/app/services/setup.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent implements OnInit {

  // @ViewChild('sessionForm') public any: NgForm;

  faqs: any; add: Boolean; edit: Boolean = false; curr: Date; response: any; search: string = '';
  allfaqs: any[] = [];  filtered: any[]; 
  types:any[] = ["DEPOSIT", "WITHDRAWAL"]; user: any;
  constructor(private api: ApiService, private use: NavComponent) { }

  ngOnInit() {
    this.user = this.use.active;
    this.curr = new Date();
    this.formReset();
  }

 
  saveFaq() {
    if(this.faqs.faqid > 0) {
      this.putFaq();
    } else {
      this.postFaq();
    }
  }

  postFaq() {
    this.api.post('faq',this.faqs).subscribe((data: any) => {
      this.formReset();
      this.use.showMsg('success', 'FAQ Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New FAQ');
    });
  }

  putFaq() {
    this.faqs.muserid = this.use.active.id; this.faqs.mdate = new Date();
    this.api.put('faq/', this.faqs).subscribe((data: any) => {
      this.formReset();
      this.use.showMsg('success', 'FAQ Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.faqs = {
        faqid: 0, title: "", answer: "", active: true,  userid: this.use.active.id, date: new Date(), muserid: null, mdate: null
      };
      this.edit = false;
    }
  }

  back() {
    this.formReset();
  }

}




